import React, { useState, useEffect, useCallback } from 'react';
import { FaTimes, FaTrash, FaEdit, FaBrain } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { Body, Image, Filters } from './styles';
import {
  Container,
  Modal,
  Header,
  Footer,
  ButtonsWrapper,
  RiveButtonWrapper
} from '../../Modal/styles';
import apiLmsConteudo from '../../../services/apiLmsConteudo';
import actionsShow from '../../../actions/Show';
import actionsPractice from '../../../actions/Practice';
import ModalAddAvatar from './ModalAddAvatar';
import ModalExcluir from './ModalExcluir';
import AvatarRive from '../Visualizar/rockfeller-content-components/AvatarRive';

function GaleriaAvatares() {
  const dispatch = useDispatch();
  //const imagePrefix = 'https://rockfeller.s3-sa-east-1.amazonaws.com/';
  const [imageUrl, setImageUrl] = useState('');
  const [esperandoUrl, setEsperandoUrl] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const [loading, setLoading] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [avatarSelectedIndex, setAvatarSelectedIndex] = useState(-1);
  const [filter, setFilter] = useState({
    categoria: '',
    subcategoria: ''
  });
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [showModalAvatars, setShowModalAvatars] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [avatar, setAvatar] = useState({});
  const [showModalExcluir, setShowModalExcluir] = useState(false);

  const show = useSelector(state => state.ShowReducer.showGaleriaAvatares);
  const onlyRiveAvatars = useSelector(
    state => state.ShowReducer.showOnlyRiveAvatars
  );

  function closeModal() {
    dispatch(actionsShow.toggleShowGaleriaAvatares());
  }

  function handleClickImage(avatar, index) {
    setAvatarSelectedIndex(index);
    setImageUrl(avatar.url);
    setAvatarId(avatar.id);
    setEsperandoUrl(avatar.esperando_url);
    setAvatar(avatar);
  }

  function handleClickSelecionar() {
    const avatar = {
      url: imageUrl,
      esperando_url: esperandoUrl,
      id: avatarId
    };
    dispatch(actionsPractice.setAvatar(avatar));
    closeModal();
  }

  const fetchAvatars = useCallback(async () => {
    setLoading(true);
    const avatars = (
      await apiLmsConteudo.get(`/avatar/?rive=${onlyRiveAvatars}`)
    ).data;
    setAvatars(avatars);
    setLoading(false);
  }, [onlyRiveAvatars]);

  async function handleClickExcluir() {
    setShowModalExcluir(true);
  }

  const fetchCategorias = useCallback(async () => {
    try {
      const response = await apiLmsConteudo.get('/categoria_avatar/');
      const categoriasFetched = response.data.map(categoria => {
        return { ...categoria, label: categoria.nome, value: categoria.nome };
      });
      const categorias = [
        { value: 'todas', label: 'Todas' },
        ...categoriasFetched
      ];
      setCategorias(categorias);
    } catch (e) {}
  }, []);

  const fetchSubcategorias = useCallback(async () => {
    try {
      const response = await apiLmsConteudo.get(
        `/subcategoria_avatar/${filter.categoria.id}`
      );
      const subcategoriasFetched = response.data.map(subcategoria => {
        return {
          ...subcategoria,
          label: subcategoria.nome,
          value: subcategoria.nome
        };
      });
      const subcategorias = [
        { value: 'todas', label: 'Todas' },
        ...subcategoriasFetched
      ];
      setSubcategorias(subcategorias);
    } catch (e) {}
  }, [filter.categoria.id]);

  useEffect(() => {
    if (show) {
      fetchAvatars();
      fetchCategorias();
    }
  }, [show, fetchAvatars, fetchCategorias]);

  function handleSelectCategoria(categoria) {
    setFilter({
      categoria,
      subcategoria: {}
    });
    setAvatarSelectedIndex(-1);
    setImageUrl('');
    if (categoria.value !== 'todas') getAvatarsFiltered(categoria.id, '0');
    else fetchAvatars();
  }

  function handleSelectSubcategoria(subcategoria) {
    setFilter({
      ...filter,
      subcategoria
    });
    setAvatarSelectedIndex(-1);
    setImageUrl('');
    if (subcategoria.value !== 'todas')
      getAvatarsFiltered(filter.categoria.id, subcategoria.id);
    else {
      if (filter.categoria.id) getAvatarsFiltered(filter.categoria.id, '0');
    }
  }

  async function getAvatarsFiltered(categoriaId, subcategoriaId) {
    const avatars = (
      await apiLmsConteudo.get(
        `/avatar/categoria/${categoriaId}/${subcategoriaId}?rive=${onlyRiveAvatars}`
      )
    ).data;
    setAvatars(avatars);
  }

  useEffect(() => {
    fetchSubcategorias();
  }, [filter.categoria.id, fetchSubcategorias]);

  useEffect(() => {
    setImageUrl('');
    setAvatarSelectedIndex(-1);
    setFilter({
      categoria: '',
      subcategoria: ''
    });
  }, [show]);

  useEffect(() => {
    if (!showModalAvatars) {
      setModeEdit(false);
      setImageUrl('');
      setAvatarSelectedIndex(-1);
    }
    if (!showModalExcluir) {
      setImageUrl('');
      setAvatarSelectedIndex(-1);
    }
  }, [showModalAvatars, showModalExcluir]);

  return (
    <Container show={show}>
      <Modal style={{ width: '60%' }}>
        <Header>
          <h1>Galeria de Avatares</h1>
          <span className="closeButton" onClick={closeModal}>
            <FaTimes />
          </span>
        </Header>
        <Filters id="filters">
          <Select
            className="select"
            options={categorias}
            onChange={handleSelectCategoria}
            placeholder="Categoria"
            value={filter.categoria ? filter.categoria : categorias[0]}
          />
          <Select
            className="select"
            options={subcategorias}
            value={filter.subcategoria ? filter.subcategoria : subcategorias[0]}
            onChange={handleSelectSubcategoria}
            placeholder="Subcategoria"
          />
        </Filters>
        <Body>
          {loading && <BeatLoader color={'rgb(0, 64, 185)'} />}
          {avatars.map((avatar, index) => {
            if (avatar.url.endsWith('.riv')) {
              return (
                <div
                  key={avatar.url}
                  style={{
                    border:
                      index === avatarSelectedIndex
                        ? '3px solid var(--secondary)'
                        : 'none',
                    transition: 'all 0.2s',
                    margin: 5,
                    position: 'relative'
                  }}
                  onClick={() => handleClickImage(avatar, index)}
                >
                  <FaBrain
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      color: 'var(--primary)'
                    }}
                  />
                  <AvatarRive avatarUrl={avatar.url} width={200} height={200} />
                </div>
              );
            }

            return (
              <Image
                key={index}
                src={avatar.url}
                onClick={() => handleClickImage(avatar, index)}
                selected={index === avatarSelectedIndex}
              />
            );
          })}
        </Body>

        <Footer>
          <RiveButtonWrapper rive={onlyRiveAvatars}>
            {onlyRiveAvatars && (
              <label htmlFor="rive_check">
                Rive <FaBrain style={{ marginLeft: 5 }} />
              </label>
            )}
          </RiveButtonWrapper>

          <ButtonsWrapper>
            <button
              onClick={handleClickExcluir}
              className="btn btn-default border-radius transition danger"
              disabled={imageUrl === ''}
              style={
                imageUrl === ''
                  ? { opacity: 0.5, marginRight: 5 }
                  : { marginRight: 5 }
              }
            >
              <FaTrash />
            </button>
            <button
              onClick={() => {
                setShowModalAvatars(true);
                setModeEdit(true);
              }}
              className="btn btn-default border-radius transition primary"
              disabled={imageUrl === ''}
              style={
                imageUrl === ''
                  ? { opacity: 0.5, marginRight: 5 }
                  : { marginRight: 5 }
              }
            >
              <FaEdit />
            </button>

            <button
              onClick={() => setShowModalAvatars(true)}
              className="btn btn-default border-radius"
              style={{ marginRight: 5 }}
            >
              Adicionar novo avatar
            </button>
            <button
              onClick={closeModal}
              className="btn btn-default border-radius"
              style={{ marginRight: 5 }}
            >
              Cancelar
            </button>
            <button
              onClick={handleClickSelecionar}
              className="btn primary border-radius transition"
              disabled={imageUrl === ''}
              style={imageUrl === '' ? { opacity: 0.5 } : {}}
            >
              Selecionar
            </button>
          </ButtonsWrapper>
        </Footer>
      </Modal>
      <ModalAddAvatar
        show={showModalAvatars}
        setShow={setShowModalAvatars}
        fetchAvatars={fetchAvatars}
        fetchCategorias={fetchCategorias}
        avatar={avatar}
        modeEdit={modeEdit}
      />
      <ModalExcluir
        avatarId={avatarId}
        fetchAvatars={fetchAvatars}
        show={showModalExcluir}
        setShow={setShowModalExcluir}
      />
    </Container>
  );
}

export default GaleriaAvatares;
