import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaChartArea,
  FaUser,
  FaBookReader,
  FaCog,
  FaSchool,
  FaUsers,
  FaChalkboardTeacher,
  FaFlag,
  FaMugHot,
  FaHeadset,
  FaAddressCard,
  FaList,
  FaPlusCircle,
  FaComment
} from 'react-icons/fa';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { useSelector } from 'react-redux';

//import AlertTemplate from 'react-alert-template-basic';

import {
  Container,
  Aside,
  Logo,
  Menu,
  Content,
  Header,
  Body,
  SubMenu
} from './styles';
import apiLmsManager from '../../services/apiLmsManager';
import AlertTemplate from '../../components/AlertTemplate';
import useOutsideClick from '../../hooks/useOutsideClick';
import UserMenu from '../../components/UserMenu';
import GaleriaImagens from '../../components/Editor/GaleriaImagens';
import GaleriaAvatares from '@/components/Editor/GaleriaAvatares';

function AppLayout(component) {
  const location = useLocation();

  const [logoUrl, setLogoUrl] = useState(localStorage.getItem('LOGO'));
  const [menus, setMenus] = useState(
    localStorage.getItem('MENU') ? JSON.parse(localStorage.getItem('MENU')) : []
  );
  const [showSubmenu, setShowSubmenu] = useState(false);
  const subMenuRef = useRef();
  const [user, setUser] = useState({ name: '', username: '', email: '' });
  const [asideWidth, setAsideWidth] = useState(190);

  const galeria = useSelector(state => state.GaleriaImagemReducer);

  useOutsideClick(subMenuRef, () => setShowSubmenu(false));

  const options = {
    timeout: 5000,
    position: positions.TOP_RIGHT,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 999999
    }
  };

  function getTitle(title) {
    if (title !== undefined) {
      return <h3 className="text-primary">{title}</h3>;
    }
    return <h3 className="text-primary">Bem vindo ao LMS!</h3>;
  }

  function openSubmenu(e) {
    e.preventDefault();
    if (showSubmenu) return;

    setShowSubmenu(true);
  }

  // function pertenceACurso() {
  //   return (
  //     location.pathname.slice(0, 7) === '/cursos' ||
  //     location.pathname.slice(0, 8) === '/divisao' ||
  //     location.pathname.slice(0, 11) === '/subdivisao' ||
  //     location.pathname.slice(0, 6) === '/livro' ||
  //     location.pathname.slice(0, 6) === '/nivel' ||
  //     location.pathname.slice(0, 9) === '/subnivel' ||
  //     location.pathname.slice(0, 7) === '/search'
  //   );
  // }

  useEffect(() => {
    apiLmsManager
      .get('/empresa/logo')
      .then(response => {
        if (response.data.logo_lms) {
          setLogoUrl(response.data.logo_lms);
        } else {
          setLogoUrl(require('../../assets/nova_logo.png'));
        }
      })
      .catch(e => {
        setLogoUrl(require('../../assets/nova_logo.png'));
      });

    // const menu = localStorage.getItem('MENU');

    // if(!menu)
    apiLmsManager.get('/menu').then(response => {
      setMenus(response.data);
      localStorage.setItem('MENU', JSON.stringify(response.data));
    });
  }, []);

  useEffect(() => {
    const userString = localStorage.getItem('@User');

    if (userString) {
      const user = JSON.parse(userString);
      setUser(user);
    } else {
      apiLmsManager.get('/user').then(({ data: user }) => {
        setUser(user);
      });
    }
  }, []);

  const icons = {
    FaChartArea: <FaChartArea className={'text-primary '} />,
    FaUser: <FaUser className="text-primary" />,
    FaAddressCard: <FaAddressCard className="text-primary" />,
    // FaChalkboardTeacher: <FaChalkboardTeacher className="text-primary" />,
    FaBookReader: <FaBookReader className="text-primary" />,
    FaSchool: <FaSchool className="text-primary" />,
    FaUsers: <FaUsers className="text-primary" />,
    FaMugHot: <FaMugHot className="text-primary" />,
    FaFlag: <FaFlag className="text-primary" />,
    FaChalkboardTeacher: <FaChalkboardTeacher className="text-primary" />,
    FaList: <FaList className="text-primary" />,
    FaCog: <FaCog className="text-primary" />,
    FaHeadset: <FaHeadset className="text-primary" />,
    FaComment: <FaComment className="text-primary" />
  };

  function onLoadAside() {
    const aside = document.getElementById('menu-lateral-dash');

    setAsideWidth(190);
    window.addEventListener('resize', () => {
      setAsideWidth(aside.clientWidth);
    });
  }

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Container>
        <Aside id="menu-lateral-dash" onLoad={onLoadAside}>
          <Logo>
            <img src={logoUrl} alt="logo" style={{ maxWidth: 157 }} />
          </Logo>
          <Menu>
            {menus.map((item, index) => {
              if (
                item.typeUser.includes(
                  localStorage.getItem('@RockfellerType')
                ) &&
                index < 4
              ) {
                return (
                  <li
                    key={`menu-item-${index}`}
                    className={
                      location.pathname === item.path ||
                      (item.path !== '/' &&
                        location.pathname.indexOf(item.path) === 0)
                        ? 'hover-item'
                        : ''
                    }
                  >
                    <Link to={item.path}>
                      {icons[item.icon]}
                      <span>{item.name}</span>
                    </Link>
                  </li>
                );
              } else return '';
            })}

            {menus.length > 4 && (
              <li>
                <a href="/" onClick={openSubmenu} style={{ cursor: 'pointer' }}>
                  <FaPlusCircle className="text-primary" />
                  <span>Mais</span>
                </a>
              </li>
            )}
            {/*<>
            <li className={location.pathname === '/' ? 'hover-item' : ''}>
              <Link to="/">
                <FaChartArea className={'text-primary '} />
                <span>Dashboard</span>
              </Link>
            </li>

            <li className={location.pathname === '/alunos' ? 'hover-item' : ''}>
              <Link to="/alunos">
                <FaUser className="text-primary" />
                <span>Alunos</span>
              </Link>
            </li>
          

            <li
              className={
                location.pathname === '/responsaveis' ? 'hover-item' : ''
              }
            >
              <Link to="/responsaveis">
                <FaAddressCard className="text-primary" />
                <span>Responsáveis</span>
              </Link>
            </li>

            <li
              className={
                location.pathname === '/professores' ||
                location.pathname === '/professor-new'
                  ? 'hover-item'
                  : ''
              }
            >
              <Link to="/professores">
                <FaChalkboardTeacher className="text-primary" />
                <span>Professores</span>
              </Link>
            </li>

            <li
              className={
                location.pathname === '/colaboradores' ? 'hover-item' : ''
              }
            >
              <Link to="/colaboradores">
                <FaAddressCard className="text-primary" />
                <span>Colaboradores</span>
              </Link>
            </li>

            {localStorage.getItem('@RockfellerType') !== 'ADM_ESCOLA' &&
              localStorage.getItem('@RockfellerType') !==
                'FUNCIONARIO_ESCOLA' && (
                <>
                  <li className={pertenceACurso() ? 'hover-item' : ''}>
                    <Link to="/cursos">
                      <FaBookReader className="text-primary" />
                      <span>Cursos</span>
                    </Link>
                  </li>

                  <li
                    className={
                      location.pathname === '/escolas' ? 'hover-item' : ''
                    }
                  >
                    <Link to="/escolas">
                      <FaSchool className="text-primary" />
                      <span>Escolas</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/parceiros' ? 'hover-item' : ''
                    }
                  >
                    <Link to="/parceiros">
                      <FaUsers className="text-primary" />
                      <span>Parceiros</span>
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/rockspot' ? 'hover-item' : ''
                    }
                  >
                    <Link to="/rockspot">
                      <FaMugHot className="text-primary" />
                      <span>Rockspot</span>
                    </Link>
                  </li>
                </>
              )}

            <li
              className={
                location.pathname === '/send-notification' ? 'hover-item' : ''
              }
            >
              <Link to="/send-notification">
                <FaFlag className="text-primary" />
                <span>Notificações</span>
              </Link>
            </li>

            {localStorage.getItem('@RockfellerType') === 'ADM_FRANQUEADORA' && (
              <>
                <li
                  className={
                    location.pathname === '/material-apoio' ? 'hover-item' : ''
                  }
                >
                  <Link to="/material-apoio">
                    <FaChalkboardTeacher className="text-primary" />
                    <span>Mat. de Apoio</span>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === '/banners' ? 'hover-item' : ''
                  }
                >
                  <Link to="/banners">
                    <FaList className="text-primary" />
                    <span>Banners</span>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === '/empresa-config' ? 'hover-item' : ''
                  }
                >
                  <Link to="/empresa-config">
                    <FaCog className="text-primary" />
                    <span>Configurações</span>
                  </Link>
                </li>
              </>
            )}

            <li
              className={
                location.pathname === '/diagnostico' ? 'hover-item' : ''
              }
            >
              <Link to="/diagnostico">
                <FaHeadset className="text-primary" />
                <span>Diagnóstico</span>
              </Link>
            </li>
            </>*/}
          </Menu>
        </Aside>
        <SubMenu ref={subMenuRef} show={showSubmenu} asideWidth={asideWidth}>
          {menus.map((item, index) => {
            if (
              item.typeUser.includes(localStorage.getItem('@RockfellerType')) &&
              index >= 4
            ) {
              return (
                <li
                  key={`menu-item-${index}`}
                  className={
                    location.pathname === item.path ||
                    (item.path !== '/' &&
                      location.pathname.indexOf(item.path) === 0)
                      ? 'hover-item'
                      : ''
                  }
                >
                  <Link to={item.path}>
                    {icons[item.icon]}
                    <span>{item.name}</span>
                  </Link>
                </li>
              );
            } else return '';
          })}
        </SubMenu>
        <Content>
          <div className="container">
            <Header>
              {getTitle(component.title)}
              <UserMenu username={user.username} name={user.name} />
            </Header>
            <Body>{component.children}</Body>
          </div>
        </Content>
      </Container>

      <GaleriaAvatares />
      {galeria.show && (
        <GaleriaImagens
          show={galeria.show}
          onImageSelect={galeria.onImageSelect}
          type={galeria.type}
        />
      )}
    </AlertProvider>
  );
}

export default AppLayout;
