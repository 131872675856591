import actionsTypes from '../constants/ActionsTypes';

const actions = {
  toggleShowModalBackground: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_BACKGROUND
  }),
  toggleShowModalImage: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_IMAGE
  }),
  toggleShowModalTable: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_TABLE
  }),
  toggleShowModalVideo: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_VIDEO
  }),
  toggleShowModalAudio: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_AUDIO
  }),
  toggleShowModalCronometro: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CRONOMETRO
  }),
  toggleShowModalButtonLink: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_BUTTON_LINK
  }),
  toggleFullScreen: () => ({
    type: actionsTypes.TOGGLE_FULL_SCREEN
  }),
  toggleShowModalQuiz: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_QUIZ
  }),
  toggleShowModalTalking: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_TALKING
  }),
  toggleShowLayoutSelector: () => ({
    type: actionsTypes.TOGGLE_SHOW_LAYOUT_SELECTOR
  }),
  toggleShowGameToolsSelector: () => ({
    type: actionsTypes.TOGGLE_SHOW_GAMETOOLS_SELECTOR
  }),
  setShowTextTools: show => ({
    type: actionsTypes.SET_SHOW_TEXT_TOOLS,
    show
  }),
  toggleShowModalControls: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CONTROLS
  }),
  toggleShowModalAddCurso: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_CURSO
  }),
  toggleShowModalAddDivisao: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_DIVISAO
  }),
  toggleShowModalAddLivro: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_LIVRO
  }),
  toggleShowModalAddItem: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_ITEM
  }),
  toggleShowModalEditAluno: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_EDIT_ALUNO
  }),
  toggleShowModalDelete: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_DELETE
  }),
  toggleShowJanelaDireita: () => ({
    type: actionsTypes.TOGGLE_SHOW_JANELA_DIREITA
  }),
  toggleShowModalClickable: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CLICKABLE
  }),
  toggleShowImageConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_IMAGE_CONFIG
  }),
  toggleShowAudioConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_AUDIO_CONFIG
  }),
  toggleShowVideoConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_VIDEO_CONFIG
  }),
  toggleShowClickableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_CLICKABLE_CONFIG
  }),
  toggleShowSlideConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SLIDE_CONFIG
  }),
  toggleShowModalLayoutConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_LAYOUT_CONFIG
  }),
  toggleShowModalExcluir: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_EXCLUIR
  }),
  toggleShowBlankBoard: () => ({
    type: actionsTypes.TOGGLE_SHOW_BLANK_BOARD
  }),
  setShowCardToast: (
    show,
    tipo,
    text,
    voice = false,
    points = 0,
    soundFeedback = true,
    timeoutToast = null
  ) => ({
    type: actionsTypes.SET_SHOW_CARD_TOAST,
    show,
    tipo,
    text,
    voice,
    points,
    soundFeedback,
    timeoutToast
  }),
  toggleShowTextConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TEXT_CONFIG
  }),
  toggleShowInputConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_INPUT_CONFIG
  }),
  toggleShowTableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TABLE_CONFIG
  }),
  toggleShowModalClonarAula: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CLONAR_AULA
  }),
  toggleShowGaleriaImagens: () => ({
    type: actionsTypes.TOGGLE_SHOW_GALERIA_IMAGENS
  }),
  toggleShowGaleriaAvatares: (showOnlyRiveAvatars = false) => ({
    type: actionsTypes.TOGGLE_SHOW_GALERIA_AVATARES,
    showOnlyRiveAvatars
  }),
  toggleShowDiceConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DICE_CONFIG
  }),
  toggleShowGameCardConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_GAME_CARD_CONFIG
  }),
  toggleShowRandomCardConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_RANDOMCARD_CONFIG
  }),
  toggleShowPlacarConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_PLACAR_CONFIG
  }),
  toggleShowExtraWord: () => ({
    type: actionsTypes.TOGGLE_SHOW_EXTRA_WORD
  }),
  toggleShowCronometroConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_CRONOMETRO_CONFIG
  }),
  toggleShowLoading: () => ({
    type: actionsTypes.TOGGLE_SHOW_LOADING
  }),
  toggleShowTotemConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TOTEM_CONFIG
  }),
  toggleShowTotem: () => ({
    type: actionsTypes.TOGGLE_SHOW_TOTEM
  }),
  toggleShowDropableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DROPABLE_CONFIG
  }),
  toggleShowModalAddAula: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_AULA
  }),
  toggleShowButtonLinkConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_BUTTON_LINK_CONFIG
  }),
  toggleShowModalSelectSlide: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_SELECT_SLIDE
  }),
  resetShowConfig: () => ({
    type: actionsTypes.RESET_SHOW_CONFIG
  }),
  toggleShowLinkedCardRenderConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_LINKED_CARD_RENDER_CONFIG
  }),
  toggleShowSelectorConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SELECTOR_CONFIG
  }),
  toggleShowCollapsedTools: (show = null) => ({
    type: actionsTypes.TOGGLE_SHOW_COLLAPSED_TOOLS,
    show
  }),
  toggleShowGameLib: () => ({
    type: actionsTypes.TOGGLE_SHOW_GAME_LIB
  }),
  toggleShowHangmanConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_HANGMAN_CONFIG
  }),
  toggleShowFoodGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_FOOD_GAME_CONFIG
  }),
  toggleShowBasketballConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_BASKETBALL_CONFIG
  }),
  toggleShowGlobeGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_GLOBE_GAME_CONFIG
  }),
  toggleShowDarkRoomConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DARK_ROOM_CONFIG
  }),
  toggleShowMemoryGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_MEMORY_GAME_CONFIG
  }),
  toggleShowParkingGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_PARKING_GAME_CONFIG
  }),
  toggleShowZooGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_ZOO_GAME_CONFIG
  }),
  toggleShowBagGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_BAG_GAME_CONFIG
  }),
  toggleShowSheepGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SHEEP_GAME_CONFIG
  }),
  toggleShowPrepositionGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_PREPOSITION_GAME_CONFIG
  }),
  toggleShowSquareConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SQUARE_CONFIG
  }),
  toggleShowPlacarTicTacToeConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_PLACAR_TIC_TAC_TOE_CONFIG
  }),
  toggleShowMonsterGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_MONSTER_GAME_CONFIG
  }),
  toggleShowFirefighterGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_FIREFIGHTER_GAME_CONFIG
  }),
  toggleShowDominoGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DOMINO_GAME_CONFIG
  }),
  toggleShowSequenceImagesGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SEQUENCE_IMAGES_GAME_CONFIG
  }),
  toggleShowWorkplaceGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_WORKPLACE_GAME_CONFIG
  }),
  toggleShowSnookerGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SNOOKER_GAME_CONFIG
  }),
  toggleShowGeniusGameConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_GENIUS_GAME_CONFIG
  }),
  toggleShowModalUnits: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_UNITS
  }),
  toggleShowModalLivros: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_LIVROS
  }),
  setHideHidables: hideHidables => ({
    type: actionsTypes.SET_HIDE_HIDABLES,
    hideHidables
  }),
  setFinishLesson: (finishLesson = false) => ({
    type: actionsTypes.SET_FINISH_LESSON,
    finishLesson
  }),
  toggleShowBoard: boardTransparent => ({
    type: actionsTypes.TOGGLE_SHOW_BOARD,
    boardTransparent
  }),
  closeBoard: () => ({
    type: actionsTypes.CLOSE_BOARD
  }),
  setSizeBoard: (widthBoard = null, heightBoard = null) => ({
    type: actionsTypes.SET_SIZE_BOARD,
    widthBoard,
    heightBoard
  }),
  setBoardTopDistance: boardTopDistance => ({
    type: actionsTypes.SET_BOARD_TOP_DISTANCE,
    boardTopDistance
  }),

  toggleShowRockAiConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_ROCK_AI_CONFIG
  }),

  toggleShowShapeSelector: () => ({
    type: actionsTypes.TOGGLE_SHOW_SHAPE_SELECTOR
  }),

  toggleShowShapeConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SHAPE_CONFIG
  }),

  toggleShowAnimationConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_ANIMATION_CONFIG
  })
};
export default actions;
