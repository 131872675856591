import React, { useState, useCallback, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  Container,
  Modal,
  Wrapper,
  Header,
  Body,
  Footer
} from '../../../Modal/styles';
import { BeatLoader } from 'react-spinners';
import upload from '../../utils/upload';
import CreatableSelect from 'react-select/creatable';
import apiLmsConteudo from '../../../../services/apiLmsConteudo';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

export default function ModalUploadAsset({
  show = false,
  setShow,
  onAssetUpload,
  accept = '*'
}) {
  const [categorias, setCategorias] = useState([]);

  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);

  const [loading, setLoading] = useState(false);
  //const [nome, setNome] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const aulaId = useSelector(state => state.AulaReducer.aulaId);

  const fetchCategorias = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiLmsConteudo.get('/categoria_asset/list');
      const categorias = response.data.map(categoria => {
        return { ...categoria, label: categoria.nome, value: categoria.nome };
      });
      setCategorias(categorias);
    } catch (e) {}
    setLoading(false);
  }, []);

  async function handleCreateCategoria(newValue, actionMeta) {
    if (actionMeta.action === 'create-option') {
      setLoading(true);
      try {
        const data = {
          nome: newValue.value
        };
        const categoria = (
          await apiLmsConteudo.post('/categoria_asset', JSON.stringify(data))
        ).data;
        setCategoriaSelecionada(categoria);
        fetchCategorias();
      } catch (e) {}
      setLoading(false);
    } else if (actionMeta.action === 'select-option') {
      newValue
        ? setCategoriaSelecionada({
            id: newValue.id,
            nome: newValue.nome
          })
        : setCategoriaSelecionada({});
    }
  }

  function closeModal() {
    setShow(false);
  }

  function onUploadProgress(progress) {
    setUploadProgress(progress);
  }

  async function handleUpload(e) {
    const files = Array.from(e.target.files);

    if (categoriaSelecionada) {
      upload(files[0], null, categoriaSelecionada, aulaId, onUploadProgress)
        .then(uploadData => {
          setUploadProgress(0);

          onAssetUpload({
            ...uploadData,
            fileType: files[0].type.slice(0, files[0].type.search('/'))
          });
        })
        .catch(error => {
          alert(error.message);
        });

      /*files.forEach(async (image, index) => {
        if (index !== 0) {
          await upload(
            image,
            nome,
            categoriaSelecionada,
            aulaId,
            onUploadProgress
          );
        }
      })*/

      closeModal();
    }
  }

  useEffect(() => {
    if (show) fetchCategorias();
  }, [fetchCategorias, show]);

  return (
    <Container show={show}>
      <Modal>
        <Header>
          <h1>Upload</h1>
          <span className="closeButton" onClick={closeModal}>
            <FaTimes />
          </span>
        </Header>
        <Body>
          <Wrapper>
            <label>Categoria</label>
            <CreatableSelect
              isClearable
              className="select"
              onChange={handleCreateCategoria}
              options={categorias}
              placeholder="Categoria"
            />
          </Wrapper>

          {/*<Wrapper>
            <label>Nome do arquivo</label>
            <input
              type="text"
              placeholder="Nome do arquivo"
              className="form-control"
              onChange={e => setNome(e.target.value)}
            />
          </Wrapper>*/}
        </Body>

        {uploadProgress > 0 && (
          <LinearProgress
            value={uploadProgress}
            variant={uploadProgress === 100 ? 'indeterminate' : 'determinate'}
          />
        )}

        <Footer>
          <button
            onClick={closeModal}
            className="btn btn-default border-radius"
            style={{ marginRight: 5 }}
          >
            Cancelar
          </button>
          <label
            className="btn primary border-radius"
            htmlFor={
              loading || !categoriaSelecionada
                ? ''
                : 'image-upload-modal-upload-image'
            }
            disabled={loading}
            style={{
              opacity: loading || !categoriaSelecionada ? '0.5' : '1'
            }}
          >
            {loading ? <BeatLoader color="#fff" /> : 'Upload'}
          </label>
        </Footer>
        <input
          type="file"
          accept={accept}
          id="image-upload-modal-upload-image"
          style={{ display: 'none' }}
          onChange={handleUpload}
          multiple
        />
      </Modal>
    </Container>
  );
}
