/**
 * mapeia os visemas
 */

const NELTRAL = 0;
const S = 1;
const R = 2;
const OH = 3;
const M = 4;
const L = 5;
const F = 6;
const E = 7;
const D = 8;
const AH = 9;
const U = 10;

export default function getVisemeNumber(viseme = 0) {
  try {
    const visemeMap = {
      0: NELTRAL,
      1: AH,
      2: AH,
      3: U,
      4: E,
      5: E,
      6: D,
      7: U,
      8: OH,
      9: R,
      10: OH,
      11: AH,
      12: E,
      13: R,
      14: L,
      15: S,
      16: S,
      17: D,
      18: F,
      19: L,
      20: OH,
      21: M
    };

    return visemeMap[viseme];
  } catch (e) {
    return 0;
  }
}
