import React, { useState, useEffect, useCallback } from 'react';

import { FaTimes } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import apiLmsConteudo from '../../../../services/apiLmsConteudo';

import {
  Container,
  Modal,
  Header,
  Footer,
  Body,
  Wrapper
} from '../../../Modal/styles';
import ModalUploadAsset from '../../Modals/ModalUploadAsset';

function ModalAddAvatar({
  show,
  setShow,
  fetchAvatars,
  fetchCategorias: fetchCategoriasGaleria,
  modeEdit,
  avatar
}) {
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatarEsperandoUrl, setAvatarEsperandoUrl] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState({});
  const [loading, setLoading] = useState(false);

  const [subcategorias, setSubcategorias] = useState([]);
  const [subcategoriaSelecionada, setSubcategoriaSelecionada] = useState({});
  const [showModalUploadAsset, setShowModalUploadAsset] = useState(false);
  const [urlType, setUrlType] = useState('');

  function closeModal() {
    fetchCategoriasGaleria();
    setCategoriaSelecionada({});
    setCategorias([]);
    setAvatarUrl('');
    setAvatarEsperandoUrl('');
    setSubcategoriaSelecionada({});
    setSubcategorias([]);
    setShow(false);
  }

  async function handleClickInserir() {
    if (subcategoriaSelecionada.id)
      try {
        const data = {
          subcategoria_id: subcategoriaSelecionada.id,
          url: avatarUrl,
          esperando_url: avatarEsperandoUrl
        };

        await apiLmsConteudo.post('/avatar', JSON.stringify(data));

        fetchAvatars();
        closeModal();
      } catch (e) {}
  }

  async function handleClickEditar() {
    if (subcategoriaSelecionada.id)
      try {
        const data = {
          subcategoria_id: subcategoriaSelecionada.id,
          url: avatarUrl,
          esperando_url: avatarEsperandoUrl
        };

        await apiLmsConteudo.put(`/avatar/${avatar.id}`, JSON.stringify(data));

        fetchAvatars();
        closeModal();
      } catch (e) {}
  }

  const fetchCategorias = useCallback(async () => {
    try {
      const response = await apiLmsConteudo.get('/categoria_avatar/');
      const categorias = response.data.map(categoria => {
        return { ...categoria, label: categoria.nome, value: categoria.nome };
      });
      setCategorias(categorias);
    } catch (e) {}
  }, []);

  const fetchSubcategorias = useCallback(async () => {
    try {
      const response = await apiLmsConteudo.get(
        `/subcategoria_avatar/${categoriaSelecionada.id}`
      );
      const subcategorias = response.data.map(subcategoria => {
        return {
          ...subcategoria,
          label: subcategoria.nome,
          value: subcategoria.nome
        };
      });
      setSubcategorias(subcategorias);
    } catch (e) {}
  }, [categoriaSelecionada.id]);

  async function handleCreateCategoria(newValue, actionMeta) {
    if (actionMeta.action === 'create-option') {
      try {
        const data = {
          nome: newValue.value
        };
        const categoria = (
          await apiLmsConteudo.post('/categoria_avatar', JSON.stringify(data))
        ).data;
        setCategoriaSelecionada(categoria);
        fetchCategorias();
      } catch (e) {}
    } else if (actionMeta.action === 'select-option') {
      newValue
        ? setCategoriaSelecionada({
            id: newValue.id,
            nome: newValue.nome
          })
        : setCategoriaSelecionada({});
    }
  }

  async function handleCreateSubcategoria(newValue, actionMeta) {
    if (actionMeta.action === 'create-option' && categoriaSelecionada.id) {
      try {
        const data = {
          nome: newValue.value,
          categoria_id: categoriaSelecionada.id
        };
        const subcategoria = (
          await apiLmsConteudo.post(
            '/subcategoria_avatar',
            JSON.stringify(data)
          )
        ).data;
        setSubcategoriaSelecionada(subcategoria);
        fetchCategorias();
      } catch (e) {}
    } else if (actionMeta.action === 'select-option') {
      newValue
        ? setSubcategoriaSelecionada({
            id: newValue.id,
            nome: newValue.nome
          })
        : setSubcategoriaSelecionada({});
    }
  }

  async function handleUploadImage(uploadData) {
    setLoading(true);

    if (uploadData) {
      switch (urlType) {
        case 'avatarUrl':
          setAvatarUrl(uploadData.url);
          break;

        case 'avatarEsperandoUrl':
          setAvatarEsperandoUrl(uploadData.url);
          break;

        default:
          setAvatarEsperandoUrl(uploadData.url);
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    if (show) fetchCategorias();
  }, [fetchCategorias, show]);

  useEffect(() => {
    if (categoriaSelecionada.id) {
      fetchSubcategorias();
    } else {
      setSubcategorias([]);
    }
  }, [categoriaSelecionada, fetchSubcategorias]);

  useEffect(() => {
    if (!modeEdit) return;
    if (!avatar) return;

    setAvatarEsperandoUrl(avatar.esperando_url);
    setAvatarUrl(avatar.url);
    setCategoriaSelecionada(avatar.subcategoria.categoria_avatar);
    setSubcategoriaSelecionada(avatar.subcategoria);
  }, [avatar, modeEdit]);

  return (
    <Container show={show}>
      <Modal style={{ width: 700 }}>
        <Header>
          <h1>Adicionar Avatar</h1>
          <span className="closeButton" onClick={closeModal}>
            <FaTimes />
          </span>
        </Header>

        <Body>
          <Wrapper>
            <label>Avatar url</label>
            <input
              className="form-control"
              value={avatarUrl}
              onChange={e => setAvatarUrl(e.target.value)}
              placeholder="http://www.example.com/img.gif"
            />
          </Wrapper>
          <Wrapper>
            <label>Upload Avatar</label>
            <button
              htmlFor="upload-image-avatar"
              className="btn border-radius"
              onClick={() => {
                setShowModalUploadAsset(true);
                setUrlType('avatarUrl');
              }}
            >
              Escolher imagem
            </button>
          </Wrapper>

          <Wrapper>
            <label>Avatar esperando url</label>
            <input
              className="form-control"
              value={avatarEsperandoUrl}
              onChange={e => setAvatarEsperandoUrl(e.target.value)}
              placeholder="http://www.example.com/img.gif"
            />
          </Wrapper>
          <Wrapper>
            <label>Upload Avatar esperando</label>
            <button
              htmlFor="upload-image-avatar-esperando"
              className="btn border-radius"
              onClick={() => {
                setShowModalUploadAsset(true);
                setUrlType('avatarEsperandoUrl');
              }}
            >
              Escolher imagem
            </button>
          </Wrapper>

          <Wrapper>
            <label>Categoria</label>
            {show &&
              (modeEdit ? (
                <CreatableSelect
                  isClearable
                  className="select"
                  onChange={handleCreateCategoria}
                  options={categorias}
                  placeholder="Categoria"
                  defaultValue={{
                    label: avatar.subcategoria.categoria_avatar.nome,
                    value: avatar.subcategoria.categoria_avatar.nome
                  }}
                />
              ) : (
                <CreatableSelect
                  isClearable
                  className="select"
                  onChange={handleCreateCategoria}
                  options={categorias}
                  placeholder="Categoria"
                />
              ))}
          </Wrapper>
          <Wrapper>
            <label>Subcategoria</label>
            {show &&
              (modeEdit ? (
                <CreatableSelect
                  isClearable
                  className="select"
                  onChange={handleCreateSubcategoria}
                  options={subcategorias}
                  placeholder="Subcategoria"
                  defaultValue={{
                    label: avatar.subcategoria.nome,
                    value: avatar.subcategoria.nome
                  }}
                />
              ) : (
                <CreatableSelect
                  isClearable
                  className="select"
                  onChange={handleCreateSubcategoria}
                  options={subcategorias}
                  placeholder="Subcategoria"
                />
              ))}
          </Wrapper>
        </Body>

        <Footer>
          <button
            onClick={closeModal}
            className="btn btn-default border-radius"
            style={{ marginRight: 5 }}
          >
            Cancelar
          </button>
          <button
            onClick={!modeEdit ? handleClickInserir : handleClickEditar}
            className="btn primary border-radius"
          >
            {loading ? 'Carregando...' : !modeEdit ? 'Inserir' : 'Editar'}
          </button>
        </Footer>
      </Modal>
      {showModalUploadAsset && (
        <ModalUploadAsset
          onAssetUpload={handleUploadImage}
          setShow={setShowModalUploadAsset}
          show={showModalUploadAsset}
        />
      )}
    </Container>
  );
}

export default ModalAddAvatar;
