import AWS from 'aws-sdk';
import apiLmsConteudo from '../../../services/apiLmsConteudo';

function getFileName(file) {
  const fileNameSplitted = file.name.split('.');

  if (fileNameSplitted.length > 1) {
    fileNameSplitted.pop();
    return fileNameSplitted.join('.');
  }

  return fileNameSplitted[0];
}

export default async function upload(
  file,
  nome = null,
  categoria = null,
  aulaId = null,
  nivelId = null,
  onUploadProgress
) {
  const fileType = file.type.split('/')[0];
  const fileSizeKb = file.size / 1000;

  if (nome === null) {
    nome = getFileName(file);
  }

  if (fileType === 'image' && fileSizeKb > 1024) {
    return Promise.reject(
      new Error('Não são permitidas imagens maiores que 1MB.')
    );
  }

  return new Promise((resolve, reject) => {
    uploadS3(file, categoria, aulaId, nivelId, onUploadProgress)
      .then(data => {
        const tipo = file.type.split('/')[0];
        const saveAssetData = {
          url: data.url,
          categoria_id: categoria ? categoria.id : null,
          tipo,
          nome
        };
        apiLmsConteudo
          .post('/assets', saveAssetData)
          .then(() => {
            resolve(data);
          })
          .catch(err => reject(err));
      })
      .catch(err => {
        reject(err);
      });
  });
}

async function uploadS3(
  file,
  categoria = null,
  aulaId = null,
  nivelId = null,
  onUploadProgress = () => {}
) {
  return new Promise((resolve, reject) => {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET
    });

    const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i;
    const fileExtension = file.name.match(patternFileExtension)[0];
    const fileName = new Date().getTime() + fileExtension;

    apiLmsConteudo
      .post('/keyname-s3', {
        aulaId: aulaId,
        fileName: fileName,
        categoria: categoria.nome
      })
      .then(async response => {
        const keyname = response.data.keyname;

        const params = {
          Bucket: S3_BUCKET,
          Key: keyname,
          Body: file,
          ACL: 'public-read'
        };

        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION
        });

        s3.putObject(params, (err, data) => {
          if (err) {
            console.log('erro ao fazer upload', err);
            reject('erro ao fazer upload: ' + err);
          } else {
            const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${encodeURIComponent(
              keyname
            )}`;
            const relativeUrl = keyname;

            if (!categoria) {
              resolve({ url, relativeUrl });
            } else {
              apiLmsConteudo
                .post('/copy-asset-s3', {
                  url,
                  aulaId
                })
                .then(response => {
                  resolve(response.data);
                })
                .catch(err => {
                  reject('Erro ao tentar copiar asset: ' + err);
                });
            }
          }
        }).on('httpUploadProgress', evt => {
          onUploadProgress(parseInt((evt.loaded * 100) / evt.total));
        });
      })
      .catch(err => {
        reject('erro ao buscar keyname: ' + err);
      });
  });
}
