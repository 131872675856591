import React from 'react';

import { FaTimes } from 'react-icons/fa';
import apiLmsConteudo from '../../../../services/apiLmsConteudo';

import { Container, Modal, Header, Footer, Body } from '../../../Modal/styles';

function ModalExcluir({ show, setShow, avatarId, fetchAvatars }) {
  async function handleClickExcluir() {
    if (avatarId !== '') {
      await apiLmsConteudo.delete(`/avatar/${avatarId}`);
      fetchAvatars();
    }
    closeModal();
  }

  function closeModal() {
    setShow(false);
  }

  return (
    <Container show={show}>
      <Modal>
        <Header>
          <h1>Excluir</h1>
          <span className="closeButton" onClick={closeModal}>
            <FaTimes />
          </span>
        </Header>

        <Body>
          <h1 style={{ textAlign: 'center' }}>
            Tem certeza que deseja excluir?
          </h1>
        </Body>

        <Footer>
          <button
            onClick={closeModal}
            className="btn btn-default border-radius"
            style={{ marginRight: 5 }}
          >
            Cancelar
          </button>
          <button
            onClick={handleClickExcluir}
            className="btn danger border-radius"
          >
            Excluir
          </button>
        </Footer>
      </Modal>
    </Container>
  );
}

export default ModalExcluir;
