import actionsTypes from '../constants/ActionsTypes';

const opcaoDefault = {
  id: null,
  numero_opcao: 1,
  correta: false,
  texto: '',
  audio: null,
  imagem: null
};

const opcaoDefault2 = {
  id: null,
  numero_opcao: 2,
  correta: false,
  texto: '',
  audio: null,
  imagem: null
};

const questaoDefault = {
  id: null,
  numero_questao: 1,
  tipo_resposta: 'MULTIPLA_ESCOLHA',
  imagem: '',
  video: '',
  pergunta: '',
  traducao: '',
  audio: '',
  audio_feedback: '',
  caractere_verdadeiro: 'V',
  caractere_falso: 'F',
  configs: {
    mostrar_resposta: false,
    pula_questao_automaticamente: false,
    palavras_extras: '',
    zoom_imagem: true
  },
  opcoes: [opcaoDefault, opcaoDefault2],
  valor: 1
};

const INITIAL_STATE = {
  id: null,
  enunciado: '',
  questoes: [],
  quizIndex: 0,
  respondido: false,
  submeter_correcao: null,
  qtd_questoes: 0
};

export default function(state = INITIAL_STATE, action) {
  let questoes = [];
  let opcoes = [];

  switch (action.type) {
    case actionsTypes.SET_QUIZ_ENUNCIADO:
      questoes = state.questoes.map(questao => {
        return questao.enunciado === state.enunciado
          ? { ...questao, enunciado: action.enunciado }
          : questao;
      });
      return { ...state, enunciado: action.enunciado, questoes };

    case actionsTypes.SET_QUIZ_TIPO_QUESTAO:
      let questoesTipo = state.questoes.map(questao => {
        return questao.numero_questao === action.data.numero_questao
          ? { ...questao, tipo_resposta: action.data.tipo }
          : questao;
      });
      return { ...state, questoes: questoesTipo };

    case actionsTypes.SET_QUIZ_QUESTAO_PERGUNTA:
      let questoesPergunta = state.questoes.map(questao => {
        return questao.numero_questao === action.data.numero_questao
          ? { ...questao, pergunta: action.data.pergunta }
          : questao;
      });
      return { ...state, questoes: questoesPergunta };

    case actionsTypes.SET_QUIZ_QUESTAO_TRADUCAO:
      questoes = state.questoes.map(questao => {
        return questao.numero_questao === action.data.numero_questao
          ? { ...questao, traducao: action.data.traducao }
          : questao;
      });
      return { ...state, questoes };

    case actionsTypes.SET_QUIZ_ADD_QUESTAO:
      let newQuestao = {
        ...questaoDefault,
        tipo_resposta:
          state.questoes.length > 0
            ? state.questoes[state.questoes.length - 1].tipo_resposta
            : 'MULTIPLA_ESCOLHA',
        enunciado:
          state.questoes.length > 0
            ? state.questoes[state.questoes.length - 1].enunciado
            : state.enunciado
      };
      return {
        ...state,
        questoes: [
          ...state.questoes,
          { ...newQuestao, numero_questao: state.questoes.length + 1 }
        ]
      };

    case actionsTypes.SET_QUIZ_QUESTAO_ADD_OPCAO:
      let questoesAddOpcao = state.questoes.map(questao => {
        return questao.numero_questao === action.numero_questao
          ? {
              ...questao,
              opcoes: [
                ...questao.opcoes,
                { ...opcaoDefault, numero_opcao: questao.opcoes.length + 1 }
              ]
            }
          : questao;
      });

      return { ...state, questoes: questoesAddOpcao };

    case actionsTypes.SET_QUIZ_TEXTO_QUESTAO:
      opcoes = [
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          0,
          action.indiceOpcao
        ),
        {
          ...state.questoes[action.indiceQuestao].opcoes[action.indiceOpcao],
          texto: action.texto,
          audio: ''
        },
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          parseInt(action.indiceOpcao) + 1
        )
      ];
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.EXCLUIR_QUIZ_OPCAO:
      opcoes = [
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          0,
          action.indiceOpcao
        ),
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          action.indiceOpcao + 1
        )
      ];

      opcoes.forEach((opcao, index) => {
        opcoes[index].numero_opcao = index + 1;
      });

      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_OPCAO_CORRETA:
      opcoes = [...state.questoes[action.indiceQuestao].opcoes];
      if (action.radio)
        opcoes.forEach((opcao, index) => {
          opcoes[index].correta = false;
        });

      opcoes = [
        ...opcoes.slice(0, action.indiceOpcao),
        {
          ...state.questoes[action.indiceQuestao].opcoes[action.indiceOpcao],
          correta: action.correta
        },
        ...opcoes.slice(parseInt(action.indiceOpcao) + 1)
      ];

      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return { ...state, questoes };

    case actionsTypes.SET_QUIZ:
      return { ...action.quiz };

    case actionsTypes.EXCLUIR_QUIZ_QUESTAO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      questoes.forEach((questao, index) => {
        questoes[index].numero_questao = index + 1;
      });

      return { ...state, questoes };

    case actionsTypes.SET_QUIZ_INDEX:
      return { ...state, quizIndex: action.quizIndex };

    case actionsTypes.SET_QUIZ_DEFAULT:
      return { ...INITIAL_STATE };

    case actionsTypes.SET_IMAGE_QUESTAO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          imagem: action.imageUrl,
          video: ''
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return { ...state, questoes };

    case actionsTypes.SET_AUDIO_QUESTAO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          audio: action.audioUrl,
          video: ''
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return { ...state, questoes };

    case actionsTypes.SET_AUDIO_FEEDBACK_QUESTAO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          audio_feedback: action.audioUrl
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return { ...state, questoes };

    case actionsTypes.SET_VIDEO_QUESTAO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          video: action.videoUrl,
          imagem: '',
          audio: ''
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return { ...state, questoes };

    case actionsTypes.DELETE_MATCH_OPTION:
      opcoes = [
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          0,
          action.indiceOpcao
        ),
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          action.indiceOpcao + 1
        )
      ];

      opcoes = [
        ...opcoes.slice(0, action.indiceOpcao - 1),
        ...opcoes.slice(action.indiceOpcao)
      ];

      opcoes.forEach((opcao, index) => {
        opcoes[index].numero_opcao = index + 1;
      });

      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_CARACTERE_VERDADEIRO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          caractere_verdadeiro: action.caractereVerdadeiro
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_CARACTERE_FALSO:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          caractere_falso: action.caractereFalso
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_QUIZ_QUESTAO_CONFIGS:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          configs: action.configs
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_QUIZ_RESPONDIDO:
      return {
        ...state,
        respondido: action.respondido
      };

    case actionsTypes.SET_QUIZ_SUBMETER_CORRECAO:
      return {
        ...state,
        submeter_correcao:
          action.submeter_correcao &&
          ['PROVA', 'TAREFA'].includes(action.submeter_correcao)
            ? action.submeter_correcao
            : null
      };

    case actionsTypes.SET_QUIZ_QUESTAO_VALOR:
      let questoesValor = state.questoes.map(questao => {
        return questao.numero_questao === action.data.numero_questao
          ? { ...questao, valor: action.data.valor }
          : questao;
      });
      return { ...state, questoes: questoesValor };

    case actionsTypes.SET_ENUNCIADO_QUESTAO:
      questoes = state.questoes.map(questao => {
        return questao.numero_questao === action.data.numero_questao
          ? { ...questao, enunciado: action.data.enunciado }
          : questao;
      });
      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_QUESTAO_QUIZ:
      return {
        ...state,
        questao: action.questao
      };

    case actionsTypes.SET_CLICK_INDEX:
      return { ...state, clickIndex: action.clickIndex };

    case actionsTypes.SET_SUBMETER_CORRECAO_QUIZ:
      return {
        ...state,
        submeter_correcao:
          action.submeterCorrecao &&
          ['PROVA', 'TAREFA'].includes(action.submeterCorrecao)
            ? action.submeterCorrecao
            : null
      };

    case actionsTypes.SET_QTD_QUESTOES_QUIZ:
      return {
        ...state,
        qtd_questoes: action.qtdQuestoes
      };

    case actionsTypes.SET_QUESTAO_DEFAULT:
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...questaoDefault,
          tipo_resposta: 'FLASH_CARD'
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];
      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_AUDIO_OPCAO:
      opcoes = [
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          0,
          action.indiceOpcao
        ),
        {
          ...state.questoes[action.indiceQuestao].opcoes[action.indiceOpcao],
          audio: action.audio,
          imagem: '',
          texto: ''
        },
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          parseInt(action.indiceOpcao) + 1
        )
      ];
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    case actionsTypes.SET_IMAGEM_OPCAO:
      opcoes = [
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          0,
          action.indiceOpcao
        ),
        {
          ...state.questoes[action.indiceQuestao].opcoes[action.indiceOpcao],
          imagem: action.imagem,
          audio: ''
        },
        ...state.questoes[action.indiceQuestao].opcoes.slice(
          parseInt(action.indiceOpcao) + 1
        )
      ];
      questoes = [
        ...state.questoes.slice(0, action.indiceQuestao),
        {
          ...state.questoes[action.indiceQuestao],
          opcoes
        },
        ...state.questoes.slice(action.indiceQuestao + 1)
      ];

      return {
        ...state,
        questoes
      };

    default:
      return state;
  }
}
