import actionsTypes from '../constants/ActionsTypes';

const actions = {
  setAvatar: avatar => ({
    type: actionsTypes.SET_AVATAR,
    avatar
  }),
  setTalkingIndex: talking_index => ({
    type: actionsTypes.SET_TALKING_INDEX,
    talking_index
  }),
  setFalasTalking: (falas, adicionando = false, removendo = false) => ({
    type: actionsTypes.SET_FALAS_TALKING,
    falas,
    adicionando,
    removendo
  }),
  setOpcoesFalaTalking: (opcoes, indexFala) => ({
    type: actionsTypes.SET_OPCOES_FALA_TALKING,
    opcoes,
    indexFala
  }),
  setPractice: practice => ({
    type: actionsTypes.SET_PRACTICE,
    practice
  }),
  setPracticeDefault: () => ({
    type: actionsTypes.SET_PRACTICE_DEFAULT
  })
};

export default actions;
