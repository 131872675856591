import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import React, { useEffect, useRef } from 'react';
import getVisemeNumber from './getVisemaNumber';

function AvatarRive({
  avatarUrl,
  width = 500,
  height = 500,
  visema = 0,
  audioUrl = null,
  visemas = [],
  setVisemas = () => {},
  onStart = () => {},
  onEnded = () => {},
  styles = {}
}) {
  const stateMachineName = 'State Machine 1';
  const inputRiveName = 'visema';

  const audioInterval = useRef(null);
  const visemaIndex = useRef(-1);

  const audioRef = useRef(new Audio());

  const { rive, RiveComponent } = useRive({
    src: avatarUrl,
    stateMachines: stateMachineName,
    autoplay: true
  });

  const visemaInput = useStateMachineInput(
    rive,
    stateMachineName,
    inputRiveName
  );

  useEffect(() => {
    if (!visemaInput) return;

    visemaInput.value = visema;
  }, [visema, visemaInput]);

  useEffect(() => {
    function playAudioWithVisemes() {
      audioRef.current.pause();
      audioRef.current.src = audioUrl;
      const audio = audioRef.current;

      if (onStart) {
        onStart(audio);
      }

      clearInterval(audioInterval.current);

      audioInterval.current = setInterval(() => {
        if (visemas.length <= visemaIndex.current) {
          return;
        }

        const currentViseme =
          visemas[visemaIndex.current < 0 ? 0 : visemaIndex.current];

        const nextViseme =
          visemas.length > visemaIndex.current + 1
            ? visemas[visemaIndex.current + 1]
            : { time: 0 };

        visemaInput.value = getVisemeNumber(currentViseme.value);

        if (
          audio.currentTime * 1000 >= currentViseme.time &&
          nextViseme.time - audio.currentTime * 1000 < 50
        ) {
          visemaIndex.current = visemaIndex.current + 1;
          //visemaInput.value = 0;
        }
      }, 5);

      audio.play();

      audio.onended = () => {
        clearInterval(audioInterval.current);
        audioInterval.current = null;
        visemaIndex.current = -1;
        visemaInput.value = 0;
        setVisemas([]);
        if (onEnded) {
          onEnded();
        }
      };
    }

    if (audioUrl && visemas.length > 0) playAudioWithVisemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl, visemaInput, visemas]);

  return (
    <div style={{ width, height, ...styles }}>
      <RiveComponent />
    </div>
  );
}

export default AvatarRive;
